/* You can add global styles to this file, and also import other style files */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "../node_modules/@fullcalendar/core/main.min.css";
@import "../node_modules/@fullcalendar/daygrid/main.min.css";
@import "../node_modules/@fullcalendar/timegrid/main.min.css";

// FORMULARIOS/PANTALLAS
.form-title-section {
    h3 {
        margin-bottom: 0.1rem;
    }

    .form-toolbar {
        p-button {
            margin-right: 1rem;
        }

        p-button:last-of-type {
            margin-right: 0;
        }
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
}

.p-field > .bold-checkbox {
    font-weight: 600;
}

.p-field.checkbox-bottom {
    display: flex;
    align-items: end;
    padding-bottom: 3px !important;
}

.p-sm-1, .p-sm-2, .p-sm-3, .p-sm-4, .p-sm-5, .p-sm-6, .p-sm-7, .p-sm-8, .p-sm-9, .p-sm-10, .p-sm-11,
.p-md-1, .p-md-2, .p-md-3, .p-md-4, .p-md-5, .p-md-6, .p-md-7, .p-md-8, .p-md-9, .p-md-10, .p-md-11,
.p-lg-1, .p-lg-2, .p-lg-3, .p-lg-4, .p-lg-5, .p-lg-6, .p-lg-7, .p-lg-8, .p-lg-9, .p-lg-10, .p-lg-11,
.p-xl-1, .p-xl-2, .p-xl-3, .p-xl-4, .p-xl-5, .p-xl-6, .p-xl-7, .p-xl-8, .p-xl-9, .p-xl-10, .p-xl-11 {
    .p-grid .p-field.checkbox-bottom {
        display: flex;
        align-items: end;
        padding-bottom: 3px !important;
        height: 3.95rem !important;
    }
}

@media screen and (max-width: $sm) {
    .p-field.p-grid > label {
        margin-bottom: 0.5rem !important;
    }

    .p-col-12, .p-sm-12, .p-md-12, .p-lg-12, .p-xl-12 {
        .p-grid .p-field.checkbox-bottom {
            height: auto !important;
        }
    }
}

@media screen and (min-width: $md) {
    .p-field.p-grid > label {
        margin-bottom: 0 !important;
    }
}

    // DIALOGS/MODALES/MENSAJES ALERT
@media screen and (max-width: $md - 1) {
    .d-sm {
        width: 95% !important;
    }
}

@media screen and (min-width: $md) and (max-width: $lg - 1) {
    .d-md {
        width: 90% !important;
    }

    .d-md-75 {
        width: 75% !important;
    }
}

@media screen and (min-width: $lg) and (max-width: $xl - 1) {
    .d-lg-70 {
        width: 70% !important;
    }
    .d-lg-50 {
        width: 40% !important;
    }
}

@media screen and (min-width: $xl) {
    .d-xl-40 {
        width: 40% !important;
    }
    .d-xl-50 {
        width: 50% !important;
    }
    .d-xl-70 {
        width: 70% !important;
    }
}

/*    .p-dialog .p-dialog-content {
        padding: 2rem 1.5rem 2rem 1.5rem !important;
    }

    .p-dialog .p-dialog-header + .p-dialog-content {
        padding: 0.5rem 1.5rem 2rem 1.5rem !important;
    }*/

    // SECCIÓN PARÁMETROS DE CORREOS
    .aoptions-title {
        font-weight: 700;
        margin-bottom: 6px;
        border-bottom: 1px solid #eaeaea;
        padding-bottom: 16px;
    }

    .aoptions .opt {
        padding: 3px 10px;
    }

    .aoptions .opt > .alnk {
        float: right;
        cursor: pointer;
    }

    .aoptions .opt:hover {
        background-color: #eee;
    }

// CHAT
.circuloChat {
    border-radius: 36px;
    width: 36px;
    height: 36px;
    overflow: hidden;
}

/*.layout-dashboard .chat div.message-own {
    float: right;
}*/

.layout-dashboard .chat div.message-own span.span-chat-time {
    border: inherit;
    background-color: inherit;
    margin: 4px 0;
    padding: 6px 6px;
    font-size: 12px;
    font-style: italic;
}

.layout-dashboard .chat div.message-from span.span-chat-time {
    border: inherit;
    background-color: inherit;
    margin: 4px 0;
    padding: 6px 6px;
    font-size: 12px;
    font-style: italic;
}


.contactChatStatusOffline {
    color: #bfcad1;
}

.contactChatStatusOnline {
    color: #26c281;
}

.bottom-separator {
    border-bottom: 1px solid #eaeaea;
}

.p-tag.p-tag-active {
    background-color: #2196F3;
    color: #ffffff;
}

.p-tag.p-tag-inactive {
    background-color: #E4E4E4;
    color: #495057;
}
